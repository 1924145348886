import * as React from "react";

import Seo from "../components/seo"


import { useRef, useEffect, useState , Suspense } from 'react'

import Navigation from "../components/navigation";
import Footer from "../components/footer";

import * as styles from "../components/pages/threeDtest.module.css"
//import Spline from '@splinetool/react-spline';
const Spline = React.lazy(() => import('@splinetool/react-spline'));


//import { Application } from '@splinetool/runtime';
/*
//
     const pageRef = useRef();
      const canvasRef = useRef(null)
 
  useEffect(() => {
    const canvas = canvasRef.current
      const spline = new Application(canvas);
      spline.load('https://prod.spline.design/2KMcF8q1JMXIHGGZ/scene.splinecode');
  }, [canvasRef])
//
            <canvas id="canvasRef" ref={canvasRef} />

  */

    /*
             const cube = useRef();
  
    function onLoad(spline) {
    const obj = spline.findObjectByName('Cube');
    console.log('load', { spline, obj });
    cube.current = obj;
  }
  //
  onLoad={onLoad}

  <Spline scene="../scene.splinecode" />
            */

  /*
  const Spline = React.lazy(() => import('@splinetool/react-spline'));
  */
const useMounted = () => {
    const [mounted, setMounted] = useState();
    // effects run only client-side
    // so we can detect when the component is hydrated/mounted
    // @see https://react.dev/reference/react/useEffect
    useEffect(() => {
        setMounted(true)
    }, [])
    return mounted
}





  
const ThreeDtest = () => {
 
  const mounted = useMounted();
  
if (mounted) {
    return(
      
      <div>
        <Navigation/>
        <Suspense fallback={<div>Loading...</div>}>
            <Spline scene="https://prod.spline.design/2KMcF8q1JMXIHGGZ/scene.splinecode" />
        </Suspense>
        <Footer />  
      </div>

)
  }

    return <span>Spline will be ignored</span>;
}

 

export const Head = () => <Seo title="3d test" />

export default ThreeDtest;


/*
    <main className={styles.indexPage} id="page">
              <Spline scene="../scene.splinecode" />

        </main>
         */


        /*  <div>
      <div className={styles.indexPageWrapper}> 
        <Navigation/>
      
        <main className={styles.indexPage} id="page">


        </main>
        <div >
          <Footer />  
        </div>
      </div>
    </div>


        */